<template>
  <div class="login-container">
    <form class="login-form" @submit.prevent="$emit('submit', form)">
      <div class="login-form__logo">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="64" height="64" rx="32" fill="#292A31"/>
          <circle cx="32" cy="26" r="4" fill="white"/>
          <path d="M24 37.7177C24 37.2458 24.1078 36.7781 24.39 36.3999C25.2231 35.2836 27.4797 33 32 33C36.5203 33 38.7769 35.2836 39.61 36.3999C39.8922 36.7781 40 37.2458 40 37.7177C40 38.5371 39.6567 39.3212 38.9237 39.6874C37.7655 40.2661 35.589 41 32 41C28.411 41 26.2345 40.2661 25.0763 39.6874C24.3433 39.3212 24 38.5371 24 37.7177Z" fill="white"/>
        </svg>
      </div>
      <div class="login-form__fields">
        <t-text-field width="320" label="Логин" id="login" v-model="form.username" />
        <t-text-field width="320" type="password" label="Пароль" id="password" v-model="form.password" />
      </div>
      <div class="login-form__submit">
        <t-btn width="240px" type="submit" id="submit">Войти</t-btn>
      </div>
    </form>
  </div>
</template>

<script>
import TTextField from "@/components/Blocks/TTextField";
import TBtn from "@/components/Blocks/TBtn";
export default {
  name: "LoginPage",
  components: {TBtn, TTextField},
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login-container {
  width: 100vw;
  height: 100vh;
  background-color: $grayscale-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  & .login-form {
    display: flex;
    flex-direction: column;
    row-gap: 44px;
    align-items: center;
    background-color: $grayscale-middle;
    border-radius: 15px;
    padding: 100px;
    &__fields {
      display: flex;
      flex-direction: column;
      row-gap: 34px;
    }
    &__sbmit {
    }
  }
}
</style>
