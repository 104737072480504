<template>
<login-page @submit="submit" />
</template>

<script>
import LoginPage from "@/components/Pages/Login";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Login",
  components: {LoginPage},
  computed: {
    ...mapGetters(['isAuthorized'])
  },
  methods: {
    ...mapActions(['LOGIN']),
    submit(form) {
      this.LOGIN(form).finally(() => {
        if (this.isAuthorized) this.$router.push('/')
      })
    }
  }
}
</script>
